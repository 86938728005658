<template>
    <v-container>
        <v-row>
            <v-col cols="4">
                <div class="pa-4" v-if="loaderdata">
                    <v-skeleton-loader v-bind="attrs"
                        type="list-item-three-line, list-item-three-line, list-item-three-line, actions">
                    </v-skeleton-loader>
                </div>
                <v-card class="pa-4" v-else>
                    <div>
                        <span class="offer">{{ pricingInfos.name }}</span>
                        <h2 class="price" v-if="pricingInfos">
                            {{ pricingInfos.price === 'Free' ? '' : '$' }}
                            {{ pricingInfos.price }}
                        </h2>
                        <span class="text-caption">Per month</span>
                    </div>
                    <v-divider class="my-4"></v-divider>
                    <v-card-text class="">
                        <div class="" v-for="(data, i) in pricingsdetails" :key="i">
                            <v-icon size="1.1em" :color="'error'" class="" v-if="data.status === '0'">{{
                                    icons.mdiClose
                            }}
                            </v-icon>
                            <v-icon size="1.1em" color="primary" class="" v-if="data.status === '1'">{{
                                    icons.mdiCheck
                            }}</v-icon>
                            <span class="accent--text">{{
                                    data.roletype.designation
                            }}</span>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <!-- <v-btn v-if="showtestBtn === true" @click="testPurchaseTransaction">Test subscription</v-btn> -->
                        <v-btn @click="testPurchaseTransaction">Test subscription</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="8">
                <v-card rounded class="pa-2">
                    <v-skeleton-loader v-bind="attrs" v-if="loaderdata"
                        type="list-item-three-line, list-item-three-line, list-item-three-line, actions">
                    </v-skeleton-loader>
                    <wallet-refill-component v-else @purchase="purchaseTransaction" :pricingItem="pricingInfos"
                        :user="this.$store.getters.getUser">
                    </wallet-refill-component>
                </v-card>
            </v-col>
            <!-- {{ temp_subscription }} -->
            <v-col></v-col>
        </v-row>
    </v-container>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiClose, mdiCheck, mdiEyeOutline, mdiEyeOffOutline, mdiAlertOctagonOutline } from '@mdi/js'
import WalletRefillComponent from './WalletRefillComponent.vue'
import { Drequest } from '@/plugins/Drequest'

var md5 = require("md5")

export default {
    components: {
        WalletRefillComponent
    },
    data() {
        return {
            data: this.$store.getters.getInitSubscription.data,
            pricingsdetails: [],
            loaderdata: true,
            loaderSubcriptionOp: false,
            pricingInfos: {},
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
            showtestBtn: false,
            payment: {},
            temp_subscription: {}
        }
    },
    methods: {
        purchaseTransaction(payment) {
            this.showtestBtn = true
            this.temp_subscription = payment
        },
        testPurchaseTransaction() {
            this.loaderSubcriptionOp = true
            var bodyFormData = {
                "pricingenterprise": {
                    "enterprise.id": null,
                    "pricing.id": this.pricingInfos.kid,
                    "user.id": this.$store.getters.getUser.id,
                    "status": "",
                    "metadata": ""
                }
            }
            Drequest.api('pricingenterprise.create')
                .data(bodyFormData)
                .raw((response) => {
                    if (response.success === true) {
                        this.$fire({
                            type: "success",
                            text: response.detail,
                            timer: 3000
                        })
                        this.loaderSubcriptionOp = false
                        this.$router.push({ name: 'proposal.list' })
                    } else {
                        this.$fire({
                            type: "error",
                            text: "Error occured, please retry later..!",
                            timer: 3000
                        })
                        this.loaderSubcriptionOp = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.loaderSubcriptionOp = false
                })
        }
    },

    beforeMount() {
        this.loaderdata = true
        Drequest.api(`lazyloading.pricingrole?dfilters=on&pricing.name:eq=${this.data}`)
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((element, index) => {
                        this.pricingsdetails.push(element)
                    })
                    this.pricingInfos = {
                        kid: this.pricingsdetails[0].pricing.id,
                        name: this.pricingsdetails[0].pricing.name,
                        price: this.pricingsdetails[0].pricing.price,
                    }
                    this.loaderdata = false
                }
                else {
                    this.loaderdata = false
                }
            })
            .catch((err) => {
                this.loaderdata = false
            });
    },
    setup() {
        const icons = {
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiAlertOctagonOutline,
            mdiClose, mdiCheck
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
</style>
